import React from 'react';
import tuur from '../images/dnit.png';
import { Link } from 'react-router-dom';
import {HashLink} from "react-router-hash-link";

const Intro = () => {
    return (
        <>
            <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about'>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left items-center" data-aos="fade-up">

                    {/* Left Image */}
                    <div className="flex justify-center lg:w-1/2">
                        <img alt="card img" className="rounded-t" src={tuur}/>
                    </div>

                    {/* Text Content */}
                    <div className="flex flex-col my-4 text-center lg:text-left lg:my-0 lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h3 className="text-3xl text-red-900 font-bold">
                            We develop high quality games, web and mobile applications for organizations, institutions and SMEs
                        </h3>

                        <div>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>
                                Our team combines experience with freshness and is ready to help develop the games and applications of your choice.
                            </p>
                        </div>

                        <div>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>
                                We take responsibility for building custom solutions for the things you do or aspire. If you can dream it, we will code it.
                            </p>
                        </div>

                        <HashLink to="/contact#contact"
                              className="text-white bg-red-900 hover:bg-red-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                            Contact us
                            <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd"
                                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                      clipRule="evenodd"></path>
                            </svg>
                        </HashLink>
                    </div>

                    {/*/!* Right Image *!/*/}
                    {/*<div className="flex justify-center lg:w-1/4">*/}
                    {/*    <img alt="card img" className="rounded-t" src={tuur}/>*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    )
}

export default Intro;
